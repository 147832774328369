import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Row, Col, CardLink, CardImg } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { ReactComponent as DowloadSVG } from '../images/icons/download.svg';

const Events = () => {
  const { folderName = '' } = useParams();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setPhotos([]);
    setLoading(true);

    if (folderName) {
      eventPhotos(folderName).then(fetchedPhotos => {
        setPhotos(fetchedPhotos);
        setLoading(false);
      }).catch(error => {
        console.error('Error fetching photos:', error);
        setLoading(false);
      });
    } 

  }, [folderName]);

  const getEvent = (folderName) => {
    return events().find(f => f.name === folderName);
  }

  const eventPhotos = async (folderName) => {
    const response = await fetch(`https://us-central1-london-irish-business-society.cloudfunctions.net/app/api/event/images?folder=${folderName}/`);
    const data = await response.json();
    return data.filter((src) => src.includes('.jpg') || src.includes('.jpeg')).map((src) => ('https://storage.googleapis.com/libs_events_photos/' + src));
  };

  const events = () => [
    {
      title: "LIBS Winter Social with Jacqueline O'Donovan",
      date: "10/12/2024",
      name: "wintersocial2024",
      photo: "https://storage.googleapis.com/libs_events_photos/wintersocial2024/3Z8A9784.jpg",
      desc: "LIBS Winter Social with Jacqueline O'Donovan in aid of Southwark Irish Pensioners Project"
    },
    {
      title: "Summer Social 2024 @ Homeboy Bar",
      date: "24/07/2024",
      name: "summersocial2024",
      photo: "https://storage.googleapis.com/libs_events_photos/summersocial2024/IMG_2835.jpg",
      desc: "Our annual Summer Social event, this year at Homeboy Bar, focused around Irish hospitality in London.",
    },
    {
      title: "Finding Your Place in the World of Work",
      date: "18/04/2024",
      name: "embassy2024",
      photo: "https://storage.googleapis.com/libs_events_photos/embassy2024/PHOTO-2024-04-18-19-14-20%20Medium.jpeg",
      desc: "An event in the Embassy of Ireland to muse on how to find our place in the world of work",
    },
    {
      title: "IWD 2024 - Breaking Barriers: Inspiring Irish Women in Business, Tech, Financial Services",
      date: "21/03/2024",
      name: "breakingbarriers",
      photo: "https://storage.googleapis.com/libs_events_photos/breakingbarriers/0B7IDq8o.jpeg",
      desc: "IWD event: 'Inspiring Irish Women in Business, Finance, Tech & the Arts .' Empowering talks, knowledge sharing & a few drinks.",
    },
    {
      title: "Green Employees: Building a Sustainable Workforce",
      date: "22/02/2024",
      name: "esg",
      photo: "https://storage.googleapis.com/libs_events_photos/esg/IMG_6291%20Medium.jpeg",
      desc: "A discussion around the role of employees in helping their companies be leaders in the race to net zero.",
    },
    {
      title: "Belonging | An evening with LIBS and Seanchoíche",
      date: "05/10/2023",
      name: "seanchoiche",
      photo: "https://storage.googleapis.com/libs_events_photos/seanchoiche/F88A6507.jpg",
      desc: "A special evening of storytelling at the London Irish Centre.",
    },
    {
      title: "Live Women's Six Nations with Sports Broadcaster Sarah Mulkerrins",
      date: "25/03/2023",
      name: "womens6nations",
      photo: "https://storage.googleapis.com/libs_events_photos/womens6nations/IMG_0127.jpg",
      desc: "Join us and Irish sports broadcaster Sarah Mulkerrins for a few drinks as we watch Ireland kick off the Women's Six Nations against Wales .",
    },
    {
      title: "Thriving in Times of Turbulence: Guidance on the Evolving Job Market",
      date: "06/07/2023",
      name: "turbulence",
      photo: "https://storage.googleapis.com/libs_events_photos/turbulence/IMG_7970.jpg",
      desc: "Thriving in Times of Turbulence is a live panel discussion between employment experts, in association with Morgan McKinley.",
    },
    {
      title: "LIBS Summer Social",
      date: "06/07/2022",
      name: "summersocial",
      photo: "https://storage.googleapis.com/libs_events_photos/summersocial/IMG_7479.jpg",
      desc: "With a glass in hand, this informal gathering was the perfect opportunity to mingle with friends old and new. ",
    },
    {
      title: "A celebration of Irish sporting heritage in London",
      date: "16/09/2021",
      name: "embassy2021",
      photo: "https://storage.googleapis.com/libs_events_photos/embassy2021/IMG_4115.jpg",
      desc: "Celebrate how the Irish diaspora in London has united and grown into a strong community, both socially and economically, through a common interest and love for sport.",
    },
    {
      title: "Irish Innovation: Celebrating the Irish Tech Startup Community",
      date: " 30/10/2019",
      name: "startupevent",
      photo: "https://storage.googleapis.com/libs_events_photos/startupevent/Huckletree_LIBS-1.jpg",
      desc: "An evening of celebration of the Irish startup community.",
    },
  ];

  return (
    <div>
      <section className="bg-libs-blue-1 pb-1">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Next Event</h2>
              <h4>Coming soon...</h4>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-4'>
        <div className="container">
          <h2>{folderName ? (
            <Link to={'/events'}>
              <i className="fa fa-arrow-left mr-1 text-body" aria-hidden="true"></i></Link>) : ''} {folderName ? getEvent(folderName).title : 'Past Events'}
          </h2>
          {folderName === '' && (
            <Row>
              {(events().map((event, index) => (
                <Col sm="4" key={index} className="mb-4">
                  <Card>
                    <CardBody className="card-body">
                      <CardTitle tag="h5">{event.title}</CardTitle>
                      <CardImg top className="card-img-top" width="100%" src={event.photo} />
                      <CardText>{event.desc}</CardText>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">{event.date}</CardSubtitle>
                      <Link className="btn text-light bg-libs-green-3" to={`/events/${event.name}`}>View Album</Link>
                    </CardBody>
                  </Card>
                </Col>
              )))}
            </Row>
          )}
          {folderName !== '' && (
            <Row>
              {loading ? (
                <p>Loading photos...</p>
              ) : (
                photos.map((photo, index) => (
                  <Col sm="4" key={index} className="mb-4">
                    <Card>
                      <img src={photo} alt={`Event photo ${index}`} className="card-img-top" />
                    </Card>
                    <CardLink
                      href={photo}
                      download={`image-${index}.jpg`}
                      className="image-download"
                    >
                      <DowloadSVG style={{ width: "2rem", height: "2rem", fill: "white" }} />
                    </CardLink>
                  </Col>
                ))
              )}
            </Row>
          )}
        </div>
      </section>
    </div>
  );
};

export default Events;